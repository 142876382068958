@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #7f12b2;
}

@layer components {
  .golain {
    --color-primary: 84 108 204;
    --color-primary-light: 101 131 249;
    --color-content: 31 41 55;
    --color-content-light: 133 135 143;
    --color-content-lighter: 193 195 213;
    --color-background: 255 255 255;
    --color-background-layer05: 250 250 250;
    --color-background-layer1: 245 245 245;
    --color-background-layer2: 233 234 236;
    --color-background-layer3: 221 222 224;
  }
}
